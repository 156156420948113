import { FileDownloadOutlined, Search } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { BoxProps, Button, Grid, InputAdornment, MenuItem, Stack, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useMutation } from "@tanstack/react-query"
import { Flex, SelectClearable } from "components/common"
import { saveAs } from "file-saver"
import { P } from "models/Permissions"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { useDebounce } from "react-use"
import { profileSelector } from "reducers/profileSlice"
import { queryClient, specialService } from "services"
import { formatDate } from "utils/common"

type Props = BoxProps & {
  onSearchChange?: (values: SpecialServiceSearchParams) => void
}

const SpecialServiceSearch = ({ onSearchChange, ...props }: Props) => {
  const { permissions = [] } = useSelector(profileSelector)
  const { control, setValue, watch } = useForm<SpecialServiceSearchParams>({
    defaultValues: {
      endDate: null,
      planName: "",
      searchText: "",
      startDate: null,
      status: "",
    },
  })

  const formValues = watch()
  const [searchParams, setSearchParams] = useState<SpecialServiceSearchParams>({})

  useDebounce(
    () => {
      const searchParams: SpecialServiceSearchParams = { ...formValues }

      if (searchParams.status === "") searchParams.status = undefined
      if (searchParams.searchText === "") searchParams.searchText = undefined
      if (searchParams.planName === "") searchParams.planName = undefined

      if (searchParams.startDate) {
        searchParams.startDate = searchParams.startDate.toISODate()
      }
      if (searchParams.endDate) {
        searchParams.endDate = searchParams.endDate.toISODate()
      }

      setSearchParams(searchParams)
      onSearchChange?.(searchParams)
    },
    500,
    [JSON.stringify(formValues)],
  )

  const handleSearch = () => {
    queryClient.invalidateQueries({ queryKey: ["specialService.fetchServices"] })
  }

  const exportBookingMutation = useMutation({ mutationFn: specialService.exportBooking })

  const handleExport = async () => {
    const blob = await exportBookingMutation.mutateAsync(searchParams)
    saveAs(
      blob,
      `DANH_SACH_BAO_HIEM${searchParams.startDate ? ` TU NGAY ${formatDate(searchParams.startDate)}` : ""}${
        searchParams.endDate ? ` DEN NGAY ${formatDate(searchParams.endDate)}` : ""
      }.xlsx`,
    )
  }

  return (
    <Flex gap={6} {...props}>
      <Grid columnSpacing={4} container rowSpacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="searchText"
            render={({ field }) => (
              <TextField
                fullWidth
                placeholder="Tìm kiếm"
                size="small"
                {...field}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="startDate"
            render={({ field }) => (
              <DatePicker
                format="dd/MM/yyyy"
                label="Từ ngày"
                {...field}
                closeOnSelect
                slotProps={{
                  field: { clearable: true, onClear: () => setValue("startDate", null) },
                  textField: { fullWidth: true, size: "small" },
                }}
              />
            )}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="endDate"
            render={({ field }) => (
              <DatePicker
                format="dd/MM/yyyy"
                label="Đến ngày"
                {...field}
                closeOnSelect
                slotProps={{
                  field: { clearable: true, onClear: () => setValue("endDate", null) },
                  textField: { fullWidth: true, size: "small" },
                }}
              />
            )}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="status"
            render={({ field }) => (
              <SelectClearable
                fullWidth
                label="Trạng thái"
                onClear={() => setValue("status", "")}
                select
                size="small"
                {...field}
              >
                <MenuItem value="CANCEL">Đã huỷ</MenuItem>
                <MenuItem value="PAID">Đã thanh toán</MenuItem>
                <MenuItem value="PENDING_PAYMENT">Chưa thanh toán</MenuItem>
                <MenuItem value="REFUND">Đã hoàn</MenuItem>
                <MenuItem value="PENDING_SUBMISSION">Chờ xác nhận</MenuItem>
              </SelectClearable>
            )}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="planName"
            render={({ field }) => (
              <SelectClearable
                fullWidth
                label="Gói"
                onClear={() => setValue("planName", "")}
                select
                size="small"
                {...field}
              >
                <MenuItem value="Cơ bản 1">Cơ bản 1</MenuItem>
                <MenuItem value="Cơ bản 2">Cơ bản 2</MenuItem>
                <MenuItem value="Phổ thông">Phổ thông</MenuItem>
                <MenuItem value="Cao cấp">Cao cấp</MenuItem>
                <MenuItem value="Thượng hạng">Thượng hạng</MenuItem>
              </SelectClearable>
            )}
          />
        </Grid>
      </Grid>
      <Stack gap={4} width={240}>
        {permissions.includes(P.SPECIAL_SERVICE_EXPORT) && (
          <LoadingButton
            loading={exportBookingMutation.isPending}
            onClick={handleExport}
            startIcon={<FileDownloadOutlined />}
            variant="outlined"
          >
            Xuất Excel
          </LoadingButton>
        )}
        <Button onClick={handleSearch}>Tìm kiếm</Button>
      </Stack>
    </Flex>
  )
}

export default SpecialServiceSearch
