export const API_URL = process.env.REACT_APP_API_URL!
export const ADMIN_USERNAME = process.env.REACT_APP_ADMIN_USERNAME ?? ""
export const ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_PASSWORD ?? ""
export const INSURANCE_DOCS_LINK = "https://drive.google.com/file/d/1Pyj31eETjCaQiPPDayfWdECHCrmYotyw/view?usp=sharing"

const ENV = process.env.REACT_APP_ENV as "development" | "production" | "staging"
export const enviroment = {
  isDev: ENV === "development",
  isProd: ENV === "production",
  isStag: ENV === "staging",
}
