import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import {
  BoxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Link,
} from "@mui/material"
import { DialogCloseButton, Flex, Text } from "components/common"
import { ReactNode, useEffect } from "react"
import { useToggle } from "react-use"
import { formatCurrency, formatDateTime } from "utils/common"
import { getChipLabelByStatus } from "views/AirportLoungeBookingCreate/utils"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  specialService?: SpecialService
}

type LineInfoProps = BoxProps & {
  label?: ReactNode
  value?: ReactNode
}

const LineInfo = ({ label, value, ...props }: LineInfoProps) => {
  return (
    <Flex columnGap={3} flexWrap="wrap" justifyContent="space-between" {...props}>
      {typeof label === "string" ? (
        <Text fontWeight={600} whiteSpace="nowrap">
          {label.trim() || "-"}
        </Text>
      ) : (
        label
      )}
      {typeof value === "string" ? (
        <Text className="max-sm:text-[cadetblue]" flex="1" textAlign="right" whiteSpace="pre-line">
          {value.trim() || "-"}
        </Text>
      ) : (
        value ?? "-"
      )}
    </Flex>
  )
}

const SpecialServiceView = ({ onClose, specialService, ...props }: Props) => {
  const [showDiscount, toggleShowDiscount] = useToggle(false)

  useEffect(() => {
    toggleShowDiscount(false)
  }, [specialService, toggleShowDiscount])

  return (
    <Dialog maxWidth="xs" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>Chi tiết</DialogTitle>
      <DialogContent>
        <div>
          <Text className="mb-1 text-lg font-bold">Thông tin gói</Text>
          <LineInfo label="Mã hợp đồng" value={specialService?.policyCode} />
          <LineInfo label="Gói bảo hiểm" value={specialService?.planName} />
          <LineInfo
            label="Mức bồi thường"
            value={
              <Text className="font-bold uppercase text-[#ef5350]">
                {formatCurrency(specialService?.coverPrice)} {specialService?.coverPriceCurrency}
              </Text>
            }
          />
          {specialService?.geoLimit && <LineInfo label="Khu vực" value={specialService?.geoLimit} />}

          <Flex className="mb-1 mt-4 gap-2">
            <Text className="text-lg font-bold">Thông tin thanh toán</Text>
            <IconButton className="p-1" color="info" onClick={() => toggleShowDiscount(!showDiscount)}>
              {showDiscount ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            </IconButton>
          </Flex>

          <LineInfo
            label="Giá bảo hiểm"
            value={
              <Text className="font-bold uppercase text-primary-main">
                {formatCurrency(specialService?.price)} {specialService?.currency}
              </Text>
            }
          />
          {showDiscount && (
            <LineInfo
              label="Lợi nhuận"
              value={
                <Text className="font-bold uppercase text-primary-main">
                  {formatCurrency(specialService?.priceCommission)} {specialService?.currency}
                </Text>
              }
            />
          )}

          <LineInfo label="Trạng thái" value={getChipLabelByStatus(specialService?.status!)} />

          <div className="mt-1 flex justify-end">
            {specialService?.status === "PENDING_PAYMENT" && (
              <Link href={specialService.documents[0]} target="_blank">
                Tải hợp đồng dự thảo
              </Link>
            )}
            {specialService?.status === "PAID" && (
              <Link href={specialService.documents[0]} target="_blank">
                Tải hợp đồng chính thức
              </Link>
            )}
          </div>
        </div>
        <div>
          <Text className="mb-1 mt-4 text-lg font-bold">Thông tin người mua</Text>
          <LineInfo label="Tên người mua" value={specialService?.passengerName} />
          <LineInfo label="Số điện thoại" value={specialService?.phoneNumber} />
          <LineInfo label="Ngày sinh" value={formatDateTime(specialService?.birthDay, "dd/MM/yyyy")} />
          <LineInfo label="Hộ chiếu" value={specialService?.passport} />
          <LineInfo label="Email" value={specialService?.email} />
          <LineInfo label="Thời gian đặt" value={formatDateTime(specialService?.createdAt)} />
          <LineInfo
            label="Thời gian bắt đầu có hiệu lực"
            value={formatDateTime(specialService?.flightJourney[0]?.boardingTime ?? "")}
          />

          <Text className="mb-1 mt-4 text-lg font-bold">Thông tin hành trình</Text>
          <LineInfo label="Mã PNR" value={specialService?.bookingCode} />
          <LineInfo
            label="Hành trình"
            value={
              <Text>{specialService?.flightJourney.map((flight) => `${flight.from} - ${flight.to}`).join(", ")}</Text>
            }
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SpecialServiceView
