import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton, Flex } from "components/common"
import { enqueueSnackbar } from "notistack"
import { useForm } from "react-hook-form"
import { queryClient, specialService } from "services"

import InsuranceStatusChip from "./components/InsuranceStatusChip"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  InsuranceItem?: SpecialService
}

const SpecialServiceDeleteDraftDialog = ({ InsuranceItem, onClose, onSuccess, ...props }: Props) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<InsurancePolicyBody>()

  const deleteDraftMutation = useMutation({ mutationFn: specialService.deleteDraft })

  const onSubmit = async () => {
    if (!InsuranceItem?.id) {
      enqueueSnackbar("Không thể thực hiện hành động với vé này.", { variant: "warning" })
      return
    }

    await deleteDraftMutation.mutateAsync({ id: InsuranceItem.id })
    enqueueSnackbar("Xoá gói bảo hiểm thành công")

    queryClient.invalidateQueries({ queryKey: ["specialService.fetchServices"] })
    reset()
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="md" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>Xoá gói bảo hiểm</DialogTitle>
      <DialogContent>
        <Grid columnSpacing={6} container rowSpacing={4}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Flex className="justify-between">
                <Typography variant="h6"> Hành khách: {InsuranceItem?.passengerName} </Typography>
              </Flex>

              <Flex className="justify-between">
                <Typography variant="h6"> Gói bảo hiểm: {InsuranceItem?.planName} </Typography>
              </Flex>

              <Flex className="justify-between">
                <Typography variant="h6">
                  {" "}
                  Hành trình: {InsuranceItem?.flightJourney?.map((trip) => `${trip.from}-${trip.to}`).join(", ")}{" "}
                </Typography>
              </Flex>

              <Flex className="justify-between">
                <Typography variant="h6">
                  {" "}
                  Trạng thái: <InsuranceStatusChip status={InsuranceItem?.status!} />{" "}
                </Typography>
              </Flex>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        <LoadingButton color="error" loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="outlined">
          Xoá gói
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default SpecialServiceDeleteDraftDialog
